import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/Layout'
import HTMLContent from '../components/Content'
import TVBHeader from '../components/TVBHeader'

// import onzeAanpakBackGround from '../../static/img/onze-aanpak-background.jpg'
import aanpakStap1 from '../../static/img/onze_aanpak_1.jpg'
import aanpakStap2 from '../../static/img/onze_aanpak_2.jpg'
import aanpakStap3 from '../../static/img/onze_aanpak_3.jpg'
import aanpakStap4 from '../../static/img/onze_aanpak_4.jpg'
import {getUrlFromImage} from '../components/Gallery';


export const OnzeAanpakPageTemplate = ({ title, content, contentComponent, titleImage }) => {

  return (
    <div>
      <TVBHeader title="Onze aanpak" background={getUrlFromImage(titleImage)} isMedium={true}/>
      <section className="section tvb-aanpak-stappen" style={{padding: '50px'}}>
        <div className="container has-text-centered">
          <h1 className="title title-aanpak">Milieubewust & efficiënt in 4 stappen</h1>
          <div className="columns">
            <div className="column" style={{position: 'relative'}}>
              <h1 style={{fontSize: '200px', fontWeight: '700', color: '#EDEDED', lineHeight: 1}}>1</h1>
              <div className="stap" style={{position: 'absolute', top: '100px'}}>
                <h5>Van oogst tot verpakking</h5>
                <p style={{fontSize: '14px',
    lineHeight: 1.2,
    color: '#000',
    fontWeight: 200}}>Automatisch oogsten en verpakken met een focus op ergonomie</p> 
              </div>
            </div>
            <div className="column" style={{position: 'relative'}}>
              <h1 style={{fontSize: '200px', fontWeight: '700', color: '#EDEDED', lineHeight: 1}}>2</h1>
              <div className="stap" style={{position: 'absolute', top: '100px'}}>
                <h5>Gezuiverd restwater</h5>
                <p style={{fontSize: '14px',
    lineHeight: 1.2,
    color: '#000',
    fontWeight: 200}}>Het niet verdampte water wordt ontsmet en opnieuw gebruikt</p> 
              </div>
            </div>
            <div className="column" style={{position: 'relative'}}>
              <h1 style={{fontSize: '200px', fontWeight: '700', color: '#EDEDED', lineHeight: 1}}>3</h1>
              <div className="stap" style={{position: 'absolute', top: '100px'}}>
                <h5>Groene energie</h5>
                <p style={{fontSize: '14px',
    lineHeight: 1.2,
    color: '#000',
    fontWeight: 200}}>Onze WKK's zorgen voor groene energie die volledig benut wordt</p> 
              </div>
            </div>
            <div className="column" style={{position: 'relative'}}>
              <h1 style={{fontSize: '200px', fontWeight: '700', color: '#EDEDED', lineHeight: 1}}>4</h1>
              <div className="stap" style={{position: 'absolute', top: '100px'}}>
                <h5>Biologisch telen</h5>
                <p style={{fontSize: '14px',
    lineHeight: 1.2,
    color: '#000',
    fontWeight: 200}}>Biologische oplossingen vormen de standaard tijdens het teeltproces</p> 
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section tvb-stappen-uitgebreid" style={{backgroundColor: '#FBF9F6', padding: '50px 0'}}>
        <div className="container">
          <div className="aanpak-stap links">
            <div className="uitleg links">
              <h1>1</h1>
              <h2>Van oogst tot verpakking</h2>
              <p>
              Momenteel worden onze tomaten geteeld op in hoogte verstelbare teeltgoten,
              waardoor de gewas- en oogstwerkzaamheden op een ergonomische manier kunnen uitgevoerd worden.
              <br/><br/>
              De geoogste tomaten worden via volautomatische karren naar de sorteer- en inpakloods getransporteerd.
              Daar worden de karren automatisch geledigd, waarna de lege karren zelfstandig terug de serre inrijden.
              Bufferbanden ledigen de volle kisten op het sorteertoestel, waarbij de tomaten gefotografeerd
              en gerangschikt worden op kleur, vorm en gewicht. Medewerkers keuren elke tomaat handmatig op
              de vereiste kwaliteit en leggen ze nadien zorgvuldig in de juiste verpakking.
              </p>
            </div>
            <div className="foto" style={{background: 'url('+aanpakStap1+') center center / cover no-repeat'}}>
            </div>
          </div>
          <div className="aanpak-stap rechts">
            <div className="foto" style={{background: 'url('+aanpakStap2+') center center / cover no-repeat'}}>
            </div>
            <div className="uitleg rechts">
              <h1>2</h1>
              <h2>Gezuiverd restwater</h2>
              <p>
              De teeltgoten in onze serres hebben een tweevoudig doel.
              Enerzijds op ergonomische manier gewassen bewerken en oogsten, anderzijds het afvoeren
              van restwater.
              <br/><br/>
              Via ondergrondse leidingen vloeit het niet verdampte water naar een
              drainkelder, waar het wordt ontsmet en opnieuw aangevoerd. Daarbovenop beschikken we
              over een 22.000 m³ groot regenwaterbassin. Doordat het irrigatiesysteem een volledig
              gesloten circuit omvat vormt dit geen impact op het milieu.
              </p>
            </div>
          </div>
          <div className="aanpak-stap links">
            <div className="uitleg links">
              <h1>3</h1>
              <h2>Groene energie</h2>
              <p>
              In 2007 investeerden we in onze eerste warmtekrachtkoppelingsinstallatie (WKK).
              Anno 2018 produceren drie WKK-centrales warmte, CO2 en 5 megawatt elektriciteit
              door de verbranding van gas.
              <br/><br/>
              De warmte en de CO2 zorgen voor het juiste groeiklimaat
              in de serre, de elektriciteit wordt dan weer ingezet om tijdens de winterperiode
              de tomatenplanten te belichten. Gedurende piekuren en tijdens de zomer leveren wij
              alle resterende elektriciteit door aan het openbare net,
              waardoor zo’n 5.500 gezinnen van elektriciteit kunnen worden voorzien.
              </p>
            </div>
            <div className="foto" style={{background: 'url('+aanpakStap3+') center center / cover no-repeat'}}>
            </div>
          </div>
          <div className="aanpak-stap rechts">
            <div className="foto" style={{background: 'url('+aanpakStap4+') center center / cover no-repeat'}}>
            </div>
            <div className="uitleg rechts">
              <h1>4</h1>
              <h2>Biologisch telen</h2>
              <p>
              De bestuiving van de tomatenbloemen wordt elke dag door meer dan 2.000 aardhommels
              uitgevoerd. Deze noeste werkers bestuiven de bloemen op het juiste moment wanneer de pollen rijp zijn.
              Ook voor de bestrijding van plagen doen “goede” insecten het grootste werk. De nuttige insecten
              onderdrukken de schadelijke, waardoor een biologisch evenwicht tussen ‘goede en slechte’ insecten wordt gecreëerd.
              <br/><br/>
              De voordelen van de biologische bestrijding liggen voor de hand: een zuiver product zonder chemisch residue.
              Bovendien is de kans op resistentie nihil, hetgeen niet het geval is bij chemische bestrijding.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

OnzeAanpakPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const OnzeAanpakPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <Helmet>
        <title>Onze aanpak</title>
        <meta name="description" content="Onze milieubewuste en efficiënte aanpak in 4 stappen" />
      </Helmet>
      <OnzeAanpakPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        titleImage={post.frontmatter.titleImage}
        content={post.html}
      />
    </Layout>
  )
}

OnzeAanpakPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default OnzeAanpakPage

export const onzeAanpakPageQuery= graphql`
  query OnzeAanpakPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        titleImage {
          childImageSharp {
              fluid(maxWidth: 1920, quality: 60) {
                  ...GatsbyImageSharpFluid
              }
              original {
                  width
                  height
              }
          }
        }
      }
    }
  }
`
